import { Link } from "react-router-dom";
import "./Partials.scss";
const Header = () => {
    return (
        <div className="container-fluid">
            <div className="Header">
                <div className="left">
                    <Link to="/">
                        <img src="/myhouserlogo.png" />
                    </Link>
                </div>
                <div className="right">
                    {/* <div className="mr-5">
                        <Link to="/session/signin/step1">Login</Link>
                    </div>
                    <div>
                        <Link to="/session/signup">Register</Link>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Header;
