import React, { lazy } from "react";
import Loadable from "../../components/Loadable/Loadable";

const HomePurchaseRentStep1 = Loadable(lazy(() => import("./HomePurchaseStep1")));
const HomePurchaseRentStep2 = Loadable(lazy(() => import("./HomePurchaseStep2")));
const HomePurchaseRentStep3 = Loadable(lazy(() => import("./HomePurchaseStep3/HomePurchaseStep3")));

const homePurchaseRoutes = [
    {
        path: "/home-purchase/step1",
        element: <HomePurchaseRentStep1 />,
    },
    {
        path: "/home-purchase/step2",
        element: <HomePurchaseRentStep2 />,
    }, {
        path: "/home-purchase/step3",
        element: <HomePurchaseRentStep3 />,
    },
];

export default homePurchaseRoutes;
