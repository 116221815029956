import { Navigate } from "react-router-dom";
import { DefaultLayout } from "../components";
import homePurchaseRoutes from "../views/home-purchase/HomePurchaseRoutes";
import sessionRoutes from "../views/sessions/SessionRoutes";

export const AllPages = () => {
    const all_routes = [
        {
            element: <DefaultLayout />,
            children: [...sessionRoutes,...homePurchaseRoutes],
        },
        {
            path: "/",
            element: <Navigate to="/session/signin/step1" />,
        },
    ];
    return all_routes;
};
