import Footer from "../partials/Footer";
import Header from "../partials/Header";
import SuspenseFallback from "../SuspenseFallback/SuspenseFallback";
import { Outlet } from "react-router-dom";

const DefaultLayout = (props) => {
    return (
        <SuspenseFallback>
            <div className="App-container">
                <div style={{margin: "0 auto"}}>
                    <header className="mb-2 mt-2">
                        <Header />
                    </header>
                    <main className="container-fluid">
                        <div className="child-outlet" >
                            <Outlet />
                        </div>
                    </main>
                    <footer>
                        <Footer />
                    </footer>
                </div>
            </div>
        </SuspenseFallback>
    );
};

export default DefaultLayout;
