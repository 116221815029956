const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    leftCalculations: null,
    monthlyExpenses: null,
    monthlyExpensesMortgageStructure: null,
    monthlyDeductibleExpense: null,
    annualExpense: null
};

const HomePurchaseStep3Slice = createSlice({
    name: "homePurchaseStep3",
    initialState,
    reducers: {
        storeLeftCalculations: (state, action) => {
            state.leftCalculations = action.payload;
        },
        changeLeftCalculations: (state, action) => {
            const { name, value } = action.payload;
            state.leftCalculations[name] = value;
        },
        storeMonthlyExpenses: (state, action) => {
            state.monthlyExpenses = action.payload;
        },
        changeMonthlyExpenses: (state, action) => {
            const { name, value } = action.payload;
            state.monthlyExpenses[name] = value;
        },
        storeMonthlyExpensesMortgageStructure: (state, action) => {
            state.monthlyExpensesMortgageStructure = action.payload; 
        },
        storeMonthlyDeductibleExpenses: (state, action) => {
            state.monthlyDeductibleExpense = action.payload; 
        },
        storeAnnualExpense: (state, action) => {
            state.annualExpense = action.payload;
        },
        changeAnnualExpenses: (state, action) => {
            const { name, value } = action.payload;
            state.annualExpense[name] = value;
        }
    },
});

const { actions } = HomePurchaseStep3Slice;

export const {
    storeLeftCalculations,
    changeLeftCalculations,
    storeMonthlyExpenses,
    changeMonthlyExpenses,
    storeMonthlyExpensesMortgageStructure,
    storeMonthlyDeductibleExpenses,
    storeAnnualExpense,
    changeAnnualExpenses
} = actions;

export const getLeftCalculations = (state) =>
    state.homePurchaseStep3.leftCalculations;

export const getMonthlyExpensesCalculations = (state) =>
    state.homePurchaseStep3.monthlyExpenses;

export const getMortgageStructureExpensesCalculations = (state) =>
    state.homePurchaseStep3.monthlyExpensesMortgageStructure;

export const getDeductibleExpenseCalculation = (state) =>
    state.homePurchaseStep3.monthlyDeductibleExpense;

export const getAnnualExpenseCalculation = (state) =>
        state.homePurchaseStep3.annualExpense;

export default HomePurchaseStep3Slice.reducer;
