import { ProgressSpinner } from "primereact/progressspinner";
import "./Loading.scss";
const Loading = () => (
    <div className="loading">
        {/* <div className="relative"> */}
            <ProgressSpinner  style={{width: '50px', height: '50px'}} />
        {/* </div> */}
    </div>
);
export default Loading;
