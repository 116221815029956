import React, { lazy } from "react";
import Loadable from "../../components/Loadable/Loadable";

const Step1 = Loadable(lazy(() => import("./login/Step1")));
const Step2 = Loadable(lazy(() => import("./login/Step2")));
const Register = Loadable(lazy(() => import("./register/Register")));
const ForgotPassword = Loadable(lazy(() => import("./forgot-password/ForgotPassword")));

const sessionRoutes = [
    {
        path: "/session/signup",
        element: <Register />,
    },
    {
        path: "/session/signin/step1",
        element: <Step1 />,
    },
    {
        path: "/session/signin/step2",
        element: <Step2 />,
    },
    {
        path: "/session/forgot-password",
        element: <ForgotPassword />,
    },
];

export default sessionRoutes;
